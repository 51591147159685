var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand"},[_c('Menu',{attrs:{"title":_vm.brandTitle}}),_c('div',{staticClass:"container"},[_c('Table',_vm._b({staticClass:"brand__table",scopedSlots:_vm._u([{key:"availabilityIcon",fn:function(){return [_c('Tooltip',{attrs:{"type":"dark","placement":"bottom"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"brand__icon"},[_c('PackageIcon',{attrs:{"size":22}})],1)]},proxy:true}])},[_c('div',{staticStyle:{"width":"180px"}},[_vm._v("Наличие SKU у ритейлера")])])]},proxy:true},{key:"commentIcon",fn:function(){return [_c('Tooltip',{attrs:{"type":"dark","placement":"bottom"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"brand__icon"},[_c('CommentIcon',{attrs:{"size":18}})],1)]},proxy:true}])},[_vm._v("Комментарии")])]},proxy:true},{key:"retailer",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"brand__item-retailer"},[_vm._v(_vm._s(item.retailerName))])]}},{key:"title",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"brand__item-title"},[_vm._v(_vm._s(item.skuName))])]}},{key:"availability",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(typeof(item.isRetailerHasSku) !== 'undefined')?_c('div',{staticClass:"brand__item-availability"},[(item.isRetailerHasSku)?_c('Tooltip',{attrs:{"type":"dark","placement":"bottom"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"brand__icon"},[(item.isRetailerHasSku)?_c('CheckIcon',{attrs:{"size":18}}):_vm._e()],1)]},proxy:true}],null,true)},[_vm._v("В наличии")]):_c('Tooltip',{attrs:{"type":"dark","placement":"bottom"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"brand__icon"},[_c('CloseIcon',{staticClass:"brand__item-not-available",attrs:{"size":18}})],1)]},proxy:true}],null,true)},[_vm._v("Не в наличии")])],1):_vm._e()]}},{key:"custom",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(_vm._s(item))]}}])},'Table',_vm.tableOptions,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }